import { render, staticRenderFns } from "./Garantia.vue?vue&type=template&id=d2d0c5e6&scoped=true"
import script from "./Garantia.vue?vue&type=script&lang=js"
export * from "./Garantia.vue?vue&type=script&lang=js"
import style0 from "./Garantia.vue?vue&type=style&index=0&id=d2d0c5e6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2d0c5e6",
  null
  
)

export default component.exports